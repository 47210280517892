<template>
  <div
    class="d-flex fill-height flex-column"
    style="gap: 24px"
    >
    <div
      >
      <list
        style="min-height: 100%; max-height: calc(100vh - 380px)"
        :key="counter"
        :dispatch="dispatch"
        @next="reload"
        ></list>
    </div>
  </div>
</template>

<script>
import List from './List'

export default {
  name: 'Home',

  data: () => ({
    dispatch: null,
    counter: 0
  }),

  methods: {
    next (attributes) {
      this.dispatch = attributes

      this.counter += 1
    },

    reload () {
      this.dispatch = null

      this.$refs.header.$refs.form.reset()
    }
  },

  components: {
    List,
  }
}
</script>
