<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    >
    <v-card-title
      style="gap: 8px"
      class="body-2"
      >
      <v-icon size="24" color="primary">mdi-package</v-icon> 

      Almacen
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      style="overflow: hidden"
      class="text--primary"
      >
      <detail
        :id="selected"
        :key="selected + '_' + band"
        ref="dialog"
        ></detail>

      <list-header></list-header>

      <template
        v-for="(stock, i) in items"
        >
        <v-hover
          v-slot="{ hover }"
          >
          <v-row
            @click="openDetail(stock.item)"
            class="caption"
            style="border-bottom: 1px solid #dedede; position: relative; cursor: pointer"
            >
            <v-col
              cols="2"
              >
              {{ stock.item.sku }}
            </v-col>

            <v-col
              cols="4"
              >
              <div
                class="description-box"
                >
                {{ stock.item.description }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ stock.item.volume }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ stock.item.weight }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ stock.stockLocation.name }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ stock.totalStock }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ stock.stock }}
              </div>
            </v-col>

            <v-col
              cols="1"
              class="text-right"
              :key="band"
              >
              <move-stock
                :stock="stock"
                @reload="reload"
                ></move-stock>

              <remove-stock
                v-if="['shibui', 'management'].includes(currentUser.currentRoleName)"
                :stock="stock"
                @reload="reload"
                ></remove-stock>
            </v-col>
          </v-row>
        </v-hover>
      </template>
    </v-card-text>

    <v-card-actions
      style="gap: 12px"
      class="justify-end"
      >
      <new-item
        @reload="reload"
        :key="band"
        ></new-item> 
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { DepotItems } from '@/graphql/queries/depot'

import Detail from './Detail'
import ListHeader from './ListHeader'
import NewItem from './NewItem'
import MoveStock from './MoveStock'
import RemoveStock from './RemoveStock'

export default {
  data: () => ({
    items: [],
    selected: null,
    band: 0
  }),

  props: {
    attributes: {
      required: false,
      type: Object
    }
  },

  created () {
    this.fetchItems()
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: {
    ListHeader,
    NewItem,
    MoveStock,
    RemoveStock,
    Detail
  },

  methods: {
    fetchItems () {
      this.$apollo.query({
        query: DepotItems,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.items = res.data.depotItems
      })
    },

    reload () {
      this.fetchItems()

      this.band += 1
    },

    openDetail (item) {
      this.selected = item.id
      
      this.$refs.dialog.dialog = true
    }
  }
}
</script>
