<template>
  <v-dialog
    v-model="dialog"
    max-width="1200"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-hover
        v-slot="{ hover }"
        >
        <v-icon
          v-if="prefetched"
          v-bind="attrs"
          v-on="on"
          color="primary"
          >
          mdi-pencil
        </v-icon>

        <v-btn
          v-else
          color="primary"
          v-bind="attrs"
          v-on="on"
          depressed
          class="px-5 ml-auto rounded-xl"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px"
          >Añadir ítem</v-btn>
      </v-hover>
    </template>

    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          {{ prefetched ? 'Editar' : 'Añadir' }} ítem
        </v-card-title>

        <v-card-text
          class="d-flex flex-row"
          style="gap: 12px"
          >
          <v-card
            flat
            :disabled="!!entry"
            style="width: 50%"
            >
            <main-header
              @next="setAttributes"
              ></main-header>
          </v-card>

          <v-divider
            vertical
            ></v-divider>

          <v-card
            flat
            :disabled="!entry"
            class="d-flex flex-column"
            style="gap: 12px; width: 50%"
            >
            <div
              v-if="error"
              class="white--text text-center font-weight-medium px-4 py-2 error rounded-lg"
              >
              {{ error }}
            </div>

            <v-text-field
              id="sku"
              label="Código producto"
              :disabled="!!prefetched"
              v-model="attributes.sku"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @blur="fetchItem"
              @keyup.enter="switchFocus('serialNumber', 'sku')"
              :rules="[
              v => !!v || 'Campo obligatorio.'
              ]"
              >
            </v-text-field>

            <v-text-field
              id="serialNumber"
              label="N° serie"
              v-model="stock.serialNumber"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @blur="fetchStock"
              @keyup.enter="switchFocus('description')"
              :disabled="!fetchedItem || noSerial"
              >
              <template
                v-slot:append
                >
                <v-tooltip 
                  bottom
                  color="primary"
                  v-if="!stock.serialNumber"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :disabled="!fetchedItem || noSerial"
                      @click="autoGenerate"
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      >
                      mdi-autorenew
                    </v-icon>
                  </template>
                  <span>Generar número de serie automáticamente</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <v-checkbox
              v-model="noSerial"
              :disabled="!fetchedItem"
              label="Sin número de serie"
              class="mt-0"
              dense
              hide-details
              ></v-checkbox>

            <v-card
              tile
              flat
              class="d-flex flex-column"
              style="gap: 12px"
              :disabled="!fetchedStock"
              >
              <v-text-field
                id="description"
                label="Descripción"
                v-model="attributes.description"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                @keyup.enter="switchFocus('volume')"
                :rules="[
                v => !!v || 'Campo obligatorio.'
                ]"
                ></v-text-field>

              <v-text-field
                id="volume"
                label="Volumen"
                v-model="attributes.volume"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                @keyup.enter="switchFocus('weight')"
                ></v-text-field>

              <v-text-field
                id="weight"
                label="Peso"
                v-model="attributes.weight"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                @keyup.enter="switchFocus('condition')"
                ></v-text-field>

              <v-select
                id="condition"
                label="Condición"
                v-model="stock.condition"
                :items="['Nuevo', 'Usado']"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                @keyup.enter="switchFocus('stock')"
                ></v-select>

              <v-text-field
                id="stock"
                label="Cantidad"
                v-model.number="stock.stock"
                type="number"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                @keyup.enter="switchFocus('obs')"
                ></v-text-field>

              <v-textarea
                id="obs"
                label="Observaciones"
                v-model="stock.obs"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                ></v-textarea>

              <v-btn
                depressed
                color="primary"
                class="rounded-lg ml-auto"
                @click="save"
                >
                Guardar
              </v-btn>
            </v-card>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { Item } from '@/graphql/queries/items'
import { Stock } from '@/graphql/queries/stocks'
import { EditItem } from '@/graphql/mutations/items'
import { CreateEntry } from '@/graphql/mutations/entries'

import MainHeader from './Header'

export default {
  data: () => ({
    dialog: false,
    error: null,
    fetchedItem: false,
    fetchedStock: false,
    noSerial: false,
    item: {
      id: null
    },
    entry: null,
    stock: {
      id: null,
      stockType: 'depot',
      stockLocationId: 1,
      serialNumber: null,
      condition: 'Nuevo',
      stock: 0,
      obs: null,
    },
    attributes: {
      sku: null,
      itemType: 'depot',
      description: null,
      volume: null,
      weight: null,
      condition: 'Nuevo',
      stocksAttributes: []
    }
  }),

  props: {
    prefetched: {
      required: false,
      type: Object
    }
  },

  watch: {
    dialog () {
      if (this.prefetched) {
        this.attributes.sku = this.prefetched.sku

        this.fetchItem()
      }
    },

    noSerial (val) {
      if (val) {
        this.stock.serialNumber = null
        this.fetchStock()
      }
    }
  },

  methods: {
    fetchItem () {
      this.$apollo.query({
        query: Item,
        variables: {
          sku: this.attributes.sku,
          itemType: this.attributes.itemType
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.item = res.data.item

        Object.keys(this.attributes).forEach( k => {
          this.attributes[k] = res.data.item[k]
        })

        this.attributes.stocksAttributes = []

        this.fetchedItem = true

        this.$nextTick( () => {
          document.getElementById('serialNumber').focus()
        })

        if (this.prefetched) {
          this.stock.serialNumber = this.prefetched.serialNumber
          this.fetchStock()
        }
      })
    },

    fetchStock () {
      this.$apollo.query({
        query: Stock,
        variables: {
          itemId: this.item.id,
          stockLocationId: this.stock.stockLocationId,
          serialNumber: this.stock.serialNumber
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        Object.keys(this.stock).forEach( k => {
          this.stock[k] = res.data.stock[k]
        })

        this.stock.stock = 0

        this.fetchedStock = true
        this.error = null
      }).catch ( error => {
        console.log(error)
        this.fetchedStock = false
        this.error = `Ya hay existencias en bodega con el número de serie ${this.stock.serialNumber}.`
      })
    },

    save () {
      var stock = Object.assign({}, this.stock);

      delete stock.stock

      this.attributes.stocksAttributes.push(stock)

      delete this.attributes.id
      delete this.attributes.__typename

      this.$apollo.mutate({
        mutation: EditItem,
        variables: {
          input: {
            id: this.item.id,
            attributes: this.attributes
          }
        }
      }).then ( res => {
        this.createEntry(this.item.id, this.attributes, this.stock)
      })
    },

    autoGenerate () {
      this.stock.serialNumber = this.item.nextStockNumber
    },

    switchFocus (element, toBlur = null) {
      if (toBlur)
        document.getElementById(toBlur).blur()

      document.getElementById(element).focus()
    },
    
    setAttributes (attributes) {
      this.entry = attributes

      this.stock.stockLocationId = this.entry.stockLocationId
    },

    createEntry (id, item, stock) {
      let newItem = {
        itemId: id,
        originId: null,
        destinationId: stock.stockLocationId,
        stockId: stock.id,
        amount: stock.stock,
        movementType: 'Almacén',
      }

      this.entry.movementsAttributes = [newItem]

      this.$apollo.mutate({
        mutation: CreateEntry,
        variables: {
          input: {
            attributes: this.entry
          }
        }
      }).then ( res => {
        this.$emit('reload')

        this.stock = {
          id: null,
          stockType: 'depot',
          serialNumber: null,
          stock: 0,
          condition: 'Nuevo',
          obs: null
        }

        this.stock.stockLocationId = this.entry.stockLocationId

        this.entry = null
        this.fetchedItem = false
        this.fetchedStock = false

        this.dialog = false
      })
    }
  },

  components: {
    MainHeader
  }
}
</script>
