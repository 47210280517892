<template>
  <v-dialog
    v-model="dialog"
    width="800"
    >
    <v-card
      v-if="item"
      >
      <v-card-title>
        Detalle {{ item.sku }}

        <v-spacer></v-spacer>

        <v-icon
          icon
          @click="dialog = false"
          >
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text
        class="text--primary"
        >
        <div>
          <v-row
            class="caption grey lighten-4 rounded-lg ma-0"
            >
            <v-col
              cols="3"
              >
              Número de serie
            </v-col>

            <v-col
              cols="3"
              >
              Bodega
            </v-col>

            <v-col
              cols="3"
              >
              Tarea
            </v-col>

            <v-col
              cols="3"
              class="text-right"
              >
              Stock
            </v-col>
          </v-row>

          <template
            v-for="(stock, i) in item.stocks"
            >
            <v-row
              style="border-bottom: 1px solid #dedede; position: relative"
              class="ma-0"
              >
              <v-col
                cols="3"
                >
                {{ stock.serialNumber || 'Sin número de serie' }}
              </v-col>

              <v-col
                cols="3"
                >
                <div
                  class="description-box"
                  >
                  {{ stock.stockLocation.name }}
                </div>
              </v-col>

              <v-col
                cols="3"
                >
                <div
                  class="description-box"
                  >
                  {{ stock.rolloutId || 'Sin asignar' }}
                </div>
              </v-col>

              <v-col
                cols="3"
                >
                <div
                  class="description-box text-right"
                  >
                  {{ stock.stock }}
                </div>
              </v-col>
            </v-row>
          </template>
        </div>

        <div
          class="mt-6 grey lighten-4 rounded-lg"
          >
          <v-row
            class="ma-0"
            >
            <v-col
              class="ml-auto"
              cols="2"
              >
              Total:
            </v-col>

            <v-col
              cols="1"
              class="text-right"
              >
              {{ item.totalStock }}
            </v-col>
          </v-row>

          <v-row
            class="ma-0"
            >
            <v-col
              class="ml-auto"
              cols="2"
              >
              Disponible:
            </v-col>

            <v-col
              cols="1"
              class="text-right"
              >
              {{ item.freeStock }}
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DetailedItem } from '@/graphql/queries/items'

export default {
  data: () => ({
    item: null,
    dialog: false
  }),

  props: {
    id: {
      type: Number | String,
      required: false
    }
  },

  watch: {
    id (val) {
      if (val) {
        this.fetchItem()
      }
    }
  },

  created () {
    if (this.id) {
      this.fetchItem()
    }
  },

  methods: {
    fetchItem () {
      this.$apollo.query({
        query: DetailedItem,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.item = res.data.detailedItem
      })
    }
  }
}
</script>
