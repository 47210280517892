var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg",staticStyle:{"box-shadow":"6px 6px var(--v-primary-lighten2)","overflow-y":"auto"}},[_c('v-card-text',{staticClass:"d-flex flex-row",staticStyle:{"gap":"24px"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"label":"Tipo de ingreso","items":['HW Proyecto', 'Consumible'],"flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.entryType),callback:function ($$v) {_vm.$set(_vm.attributes, "entryType", $$v)},expression:"attributes.entryType"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"label":"Proyecto","items":_vm.projects,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.projectId),callback:function ($$v) {_vm.$set(_vm.attributes, "projectId", $$v)},expression:"attributes.projectId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticClass:"rounded-lg",staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":"ID Tarea","items":_vm.rollouts,"item-text":"id","return-object":"","hide-details":"","dense":""},model:{value:(_vm.rollout),callback:function ($$v) {_vm.rollout=$$v},expression:"rollout"}})],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.rollout)?_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":"Sitio","value":_vm.rollout.site.siteId,"hide-details":"","dense":""}}):_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"background-color":"white"},attrs:{"outlined":"","label":"Sitio","hide-details":"","dense":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"label":"Procedencia","items":['Delivery', 'Desmonte'],"flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.origin),callback:function ($$v) {_vm.$set(_vm.attributes, "origin", $$v)},expression:"attributes.origin"}})],1),(_vm.attributes.origin == 'Delivery')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"label":"Proveedor","flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.provider),callback:function ($$v) {_vm.$set(_vm.attributes, "provider", $$v)},expression:"attributes.provider"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"label":"Bodega","items":_vm.warehouses,"item-text":"name","item-value":"id","offset":"","flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.stockLocationId),callback:function ($$v) {_vm.$set(_vm.attributes, "stockLocationId", $$v)},expression:"attributes.stockLocationId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"label":"SKU","flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.sku),callback:function ($$v) {_vm.$set(_vm.attributes, "sku", $$v)},expression:"attributes.sku"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"label":"Guía despacho/SMR","flat":"","outlined":"","dense":"","hide-details":"","rules":[
                v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes.smr),callback:function ($$v) {_vm.$set(_vm.attributes, "smr", $$v)},expression:"attributes.smr"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"label":"Observaciones","flat":"","outlined":"","dense":"","hide-details":"","rows":"1"}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column shrink",staticStyle:{"gap":"24px","width":"96px"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"rounded-pill",staticStyle:{"height":"40px"},style:(hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'),attrs:{"color":"primary"},on:{"click":_vm.reset}},[_vm._v(" Nuevo ")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{staticClass:"rounded-pill",staticStyle:{"height":"40px","width":"96px"},style:(hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'),attrs:{"color":"primary"},on:{"click":_vm.next}},[_vm._v(" Ok ")])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }