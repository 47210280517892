<template>
  <div
    class="fill-height"
    >
    <div
      class="px-6 pt-6 d-flex justify-space-between"
      >
      <v-tabs
        v-model="tab"
        height="36"
        color="primary"
        >
        <v-tab
          >
          Inicio
        </v-tab>
        <v-tab
          >
          Ingreso
        </v-tab>
        <v-tab
          >
          Egreso
        </v-tab>
        <v-tab
          >
          Almacen
        </v-tab>
      </v-tabs>

      <search
        ></search>

      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          outlined
          class="rounded-b-0 rounded-tr-lg rounded-tl-0"
          color="primary"
          style="margin-bottom: -1px; margin-left: -1px; border-width: 0px 0px 2px 0px"
          :style="hover ? 'box-shadow: 0px 6px var(--v-primary-base); transform: translate(0px, -6px)' : 'box-shadow: 0 0'"
          @click="logout"
          >
          <v-icon color="primary">mdi-account-off</v-icon> Cerrar sesión
        </v-btn>
      </v-hover>
    </div>

    <v-tabs-items
      v-model="tab"
      style="height: calc(100% - 60px)"
      class="primary lighten-4 pa-6"
      >
      <v-tab-item
        >
        <main-page
          :key="tab"
          ></main-page>
      </v-tab-item>

      <v-tab-item
        >
        <entries
          ></entries>
      </v-tab-item>

      <v-tab-item
        >
        <dispatch
          ></dispatch>
      </v-tab-item>

      <v-tab-item
        >
        <depot
          ></depot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { TokenService } from '@/services/storageService'

import MainPage from '@/components/home/Main'
import Entries from '@/components/entries/Main'
import Dispatch from '@/components/dispatch/Main'
import Depot from '@/components/depot/Main'
import Search from '@/components/shared/Search'

export default {
  name: 'Home',

  data: () => ({
    tab: 0
  }),

  watch: {
    tab (val) {
      history.pushState(
        {},
        null,
        this.$route.path + '?tab=' + val
      )
    }
  },

  created () {
    this.tab = parseInt(this.$route.query.tab || 0)
  },

  methods: {
    logout () {
      TokenService.removeToken()
      this.$router.go('')
    },
  },

  components: {
    MainPage,
    Entries,
    Dispatch,
    Search,
    Depot
  }
}
</script>
