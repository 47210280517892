<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="rounded-lg"
    >
    <v-card-text
      class="d-flex flex-row"
      style="gap: 24px"
      >
      <div
        style="flex: 1"
        >
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-row>
            <v-col
              cols="2"
              >
              <v-select
                label="Tipo de ingreso"
                :items="['HW Proyecto', 'Consumible']"
                v-model="attributes.entryType"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-select>
            </v-col>

            <v-col
              cols="2"
              >
              <v-select
                label="Proyecto"
                :items="projects"
                v-model="attributes.projectId"
                item-text="name"
                item-value="id"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-select>
            </v-col>

            <v-col
              cols="2"
              >
              <v-autocomplete
                outlined
                label="ID Tarea"
                v-model="rollout"
                :items="rollouts"
                item-text="id"
                return-object
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="2"
              >
              <v-text-field
                v-if="rollout"
                outlined
                label="Sitio"
                :value="rollout.site.siteId"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-text-field>

              <v-text-field
                v-else
                outlined
                label="Sitio"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              >
              <v-select
                label="Procedencia"
                :items="['Delivery', 'Desmonte']"
                v-model="attributes.origin"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-select>
            </v-col>

            <v-col
              cols="2"
              v-if="attributes.origin == 'Delivery'"
              >
              <v-text-field
                label="Proveedor"
                v-model="attributes.provider"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              >
              <v-select
                label="Bodega"
                :items="warehouses"
                item-text="name"
                item-value="id"
                v-model="attributes.stockLocationId"
                offset
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-select>
            </v-col>

            <v-col
              cols="2"
              >
              <v-text-field
                label="SKU"
                v-model="attributes.sku"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-col>

            <v-col
              cols="2"
              >
              <v-text-field
                label="Guía despacho/SMR"
                v-model="attributes.smr"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                  v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              >
              <v-textarea
                label="Observaciones"
                flat
                outlined
                dense
                hide-details
                rows="1"
                class="rounded-lg"
                ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div
        class="d-flex flex-column shrink"
        style="gap: 24px; width: 96px"
        >
        <v-hover
          v-slot="{ hover }"
          >
          <v-btn
            class="rounded-pill"
            color="primary"
            :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
            style="height: 40px"
            @click="reset"
            >
            Nuevo
          </v-btn>
        </v-hover>

        <v-hover
          v-slot="{ hover }"
          >
          <v-btn
            class="rounded-pill"
            color="primary"
            :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
            style="height: 40px; width: 96px"
            @click="next"
            >
            Ok
          </v-btn>
        </v-hover>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Projects } from '@/graphql/queries/projects'
import { Rollouts } from '@/graphql/queries/rollouts'
import { StockLocations } from '@/graphql/queries/stock_locations'

export default {
  data: () => ({
    valid: true,
    rollout: null,
    projects: [],
    rollouts: [],
    warehouses: [],
    attributes: {
      entryType: null,
      projectId: null,
      origin: null,
      provider: null,
      stockLocationId: null,
      rolloutId: null,
      sku: null,
      smr: null,
      obs: null
    }
  }),

  created () {
    this.fetchProjects ()
    this.fetchStockLocations ()
  },                                                             

  watch: {
    'attributes.projectId' (val) {
      if (val) {
        this.fetchRollouts()
      } else {
        this.rollouts = []
      }
    },

    rollout (rollout) {
      this.attributes.rolloutId = rollout.id
    }
  },

  methods: {                                                     
    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: Rollouts,
        variables: {
          projectId: this.attributes.projectId,
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rollouts
      })
    },

    fetchStockLocations () {
      this.$apollo.query({
        query: StockLocations,
      }).then ( res => {
        this.warehouses = res.data.stockLocations
      })                                                         
    },

    // ima need this for dispatch
    swapLocations (source) {
      if (source == 'provider') {
        let opposite = this.warehouses.find( v => v.id != this.attributes.providerId )
        this.attributes.stockLocationId = opposite.id
      } else {
        let opposite = this.warehouses.find( v => v.id != this.attributes.stockLocationId )
        this.attributes.providerId = opposite.id
      }
    },

    reset () {
      this.$router.go() 
    },

    next () {
      // call apollo
      if (this.$refs.form.validate()) {
        this.$emit('next', this.attributes)
      }
    }
  }                                                              
}
</script>
