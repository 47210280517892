<template>
  <div>
    <div
      >
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        class="d-flex flex-column"
        style="gap: 12px"
        >
        <v-select
          label="Proyecto"
          :items="projects"
          v-model="attributes.projectId"
          item-text="name"
          item-value="id"
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          ></v-select>

        <v-text-field
          label="Proveedor"
          v-model="attributes.provider"
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          ></v-text-field>

        <v-select
          label="Bodega"
          :items="warehouses"
          item-text="name"
          item-value="id"
          v-model="attributes.stockLocationId"
          offset
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          :rules="[
          v => !!v || 'Campo obligatorio'
          ]"
          ></v-select>

        <v-text-field
          label="SKU"
          v-model="attributes.sku"
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          ></v-text-field>

        <v-text-field
          label="Guía despacho/SMR"
          v-model="attributes.smr"
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          ></v-text-field>

        <v-textarea
          label="Observaciones"
          flat
          outlined
          dense
          hide-details
          class="rounded-lg"
          ></v-textarea>
      </v-form>
    </div>

    <div
      class="mt-3 text-right"
      >
      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          class="rounded-lg"
          color="primary"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px; width: 96px"
          @click="next"
          >
          Ok
        </v-btn>
      </v-hover>
    </div>
  </div>
</template>

<script>
import { Projects } from '@/graphql/queries/projects'
import { StockLocations } from '@/graphql/queries/stock_locations'

export default {
  data: () => ({
    valid: true,
    rollout: null,
    projects: [],
    warehouses: [],
    attributes: {
      entryType: 'Consumible',
      projectId: null,
      provider: null,
      stockLocationId: null,
      sku: null,
      smr: null,
      obs: null
    }
  }),

  created () {
    this.fetchProjects ()
    this.fetchStockLocations ()
  },                                                             

  methods: {                                                     
    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    },

    fetchStockLocations () {
      this.$apollo.query({
        query: StockLocations,
      }).then ( res => {
        this.warehouses = res.data.stockLocations
      })                                                         
    },

    // ima need this for dispatch
    swapLocations (source) {
      if (source == 'provider') {
        let opposite = this.warehouses.find( v => v.id != this.attributes.providerId )
        this.attributes.stockLocationId = opposite.id
      } else {
        let opposite = this.warehouses.find( v => v.id != this.attributes.stockLocationId )
        this.attributes.providerId = opposite.id
      }
    },

    reset () {
      this.$router.go() 
    },

    next () {
      // call apollo
      if (this.$refs.form.validate()) {
        this.$emit('next', this.attributes)
      }
    }
  }                                                              
}
</script>
