<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="rounded-lg"
    >
    <v-card-text
      class="d-flex flex-row"
      style="gap: 24px"
      >
      <div
        style="flex: 1"
        >
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-row>
            <v-col
              cols="4"
              >
              <v-select
                label="Proyecto"
                :items="projects"
                v-model="attributes.projectId"
                item-text="name"
                item-value="id"
                flat
                outlined
                dense
                hide-details
                class="rounded-lg"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-select>
            </v-col>

            <v-col
              cols="2"
              >
              <v-autocomplete
                outlined
                label="ID Tarea"
                v-model="rollout"
                :items="rollouts"
                item-text="id"
                return-object
                hide-details
                dense
                class="rounded-lg"
                style="background-color: white"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="2"
              >
              <v-text-field
                v-if="rollout"
                outlined
                label="Sitio"
                :value="rollout.site.siteId"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-text-field>

              <v-text-field
                v-else
                outlined
                label="Sitio"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-text-field>
            </v-col>

            <v-col
              cols="4"
              >
              <v-autocomplete
                outlined
                label="Tipo de movimiento"
                v-model="attributes.movementType"
                :items="movementTypes"
                item-text="id"
                item-value="id"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="4"
              v-if="['Mov. Interno'].includes(attributes.movementType)"
              >
              <v-autocomplete
                outlined
                label="Bodega de procedencia"
                v-model="attributes.sourceId"
                :items="warehouses"
                item-text="name"
                item-value="id"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                @change="swapLocations('source')"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="2"
              v-if="['Mov. Nueva Tarea', 'Mov. Interno'].includes(attributes.movementType)"
              >
              <v-autocomplete
                outlined
                v-if="attributes.movementType == 'Mov. Interno'"
                label="Bodega de recepción"
                v-model="attributes.stockLocationId"
                :items="warehouses"
                item-text="name"
                item-value="id"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                @change="swapLocations('origin')"
                ></v-autocomplete>

              <v-autocomplete
                outlined
                v-else
                label="Tarea de recepción"
                v-model="attributes.rolloutId"
                :items="rollouts"
                item-text="id"
                item-value="id"
                hide-details
                dense
                style="background-color: white"
                class="rounded-lg"
                @change="swapLocations('origin')"
                ></v-autocomplete>
            </v-col>

            <v-col
              cols="2"
              v-if="attributes.rolloutId"
              >
              <v-text-field
                outlined
                dense
                hide-details
                label="Sitio de recepción"
                style="background-color: white"
                class="rounded-lg"
                :value="rollouts.find( v => v.id == attributes.rolloutId).site.siteId"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              >
              <v-textarea
                label="Observaciones"
                flat
                outlined
                dense
                hide-details
                rows="1"
                class="rounded-lg"
                ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div
        class="d-flex flex-column shrink"
        style="gap: 24px; width: 96px"
        >
        <v-hover
          v-slot="{ hover }"
          >
          <v-btn
            class="rounded-pill"
            color="primary"
            :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
            style="height: 40px"
            >
            Nuevo
          </v-btn>
        </v-hover>

        <v-hover
          v-slot="{ hover }"
          >
          <v-btn
            class="rounded-pill"
            color="primary"
            :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
            style="height: 40px; width: 96px"
            @click="next"
            >
            Ok
          </v-btn>
        </v-hover>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Projects } from '@/graphql/queries/projects'
import { Rollouts, Rollout } from '@/graphql/queries/rollouts'

import { StockLocations } from '@/graphql/queries/stock_locations'

export default {
  data: () => ({
    valid: true,
    rollout: null,
    projects: [],
    rollouts: [],
    warehouses: [],
    movementTypes: [
      "Envío a terreno",
      "Devolución",
      "Mov. Nueva Tarea",
      "Mov. Interno",
      "Enajenación",
    ],
    state: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
    attributes: {
      projectId: null,
      originType: null,
      // this is the reception stock location
      stockLocationId: null,
      // this is the stock location where stock is currently assigned
      sourceId: null,
      // this is the rollout where stock is currently assigned
      originId: null,
      // this is the receiption rollout
      rolloutId: null,
      movementType: null,
      obs: null
    }
  }),

  created () {
    this.fetchProjects ()
    this.fetchStockLocations ()

    if (this.$route.query.rid) {
      this.fetchRollout ()
    }
  },                                                             

  watch: {
    'attributes.projectId' (val) {
      if (val) {
        this.fetchRollouts()
      } else {
        this.rollouts = []
      }
    },

    rollout (rollout) {
      this.attributes.originId = this.rollout.id
    }
  },

  methods: {                                                     
    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    },

    fetchRollout () {
      this.$apollo.query({
        query: Rollout,
        variables: {
          id: this.$route.query.rid,
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollout = res.data.rollout
        this.attributes.projectId = this.rollout.projectId
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: Rollouts,
        variables: {
          projectId: this.attributes.projectId,
          state: this.state
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rollouts
      })
    },

    fetchStockLocations () {
      this.$apollo.query({
        query: StockLocations,
      }).then ( res => {
        this.warehouses = res.data.stockLocations
      })                                                         
    },

    // ima need this for dispatch
    swapLocations (source) {
      if(['Mov. Interno'].includes(this.attributes.movementType)) {
        if (source == 'source') {
          let opposite = this.warehouses.find( v => v.id != this.attributes.sourceId )
          this.attributes.stockLocationId = opposite.id
        } else {
          let opposite = this.warehouses.find( v => v.id != this.attributes.stockLocationId )
          this.attributes.sourceId = opposite.id
        }
      }
    },

    reset () {
      this.$router.go() 
    },

    next () {
      // call apollo
      if (this.$refs.form.validate()) {
        this.$emit('next', this.attributes)
      }
    }
  }                                                              
}
</script>
