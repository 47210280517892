import gql from 'graphql-tag'

export const EditItem = gql`
  mutation editItem($input: EditItemInput!) {
    editItem(input: $input) {
      success
    }
  }
`

export const MoveDepotItem = gql`
  mutation moveDepotItem($input: MoveDepotItemInput!) {
    moveDepotItem(input: $input) {
      success
    }
  }
`

export const RemoveDepotItem = gql`
  mutation removeDepotItem($input: RemoveDepotItemInput!) {
    removeDepotItem(input: $input) {
      success
    }
  }
`
