<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        small
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-package-up
        </v-icon>
      </v-btn>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        Asignar a tarea
      </v-card-title>

      <v-card-text
        class="text-right"
        >
        <v-form
          class="d-flex flex-column"
          style="gap: 12px"
          ref="form"
          v-model="valid"
          lazy-validation
          >
          <v-text-field
            outlined
            label="Código producto"
            :value="stock.item.sku"
            hide-details
            readonly
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-text-field>

          <v-select
            label="Proyecto"
            :items="projects"
            v-model="projectId"
            item-text="name"
            item-value="id"
            flat
            outlined
            dense
            hide-details
            class="rounded-lg"
            :rules="[
            v => !!v || 'Campo obligatorio'
            ]"
            ></v-select>

          <v-autocomplete
            outlined
            label="ID Tarea"
            v-model="rollout"
            :items="rollouts"
            item-text="id"
            return-object
            hide-details
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-autocomplete>

          <v-text-field
            v-if="rollout"
            outlined
            label="Sitio"
            :value="rollout.site.siteId"
            hide-details
            readonly
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-text-field>

          <v-text-field
            v-else
            outlined
            label="Sitio"
            hide-details
            readonly
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-text-field>

          <v-text-field
            v-if="rollout"
            outlined
            label="Supervisor"
            :value="rollout.supervisor"
            hide-details
            readonly
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-text-field>

          <v-text-field
            v-else
            outlined
            label="Supervisor"
            hide-details
            readonly
            dense
            style="background-color: white"
            class="rounded-lg"
            ></v-text-field>

          <v-text-field
            outlined
            label="Cantidad"
            v-model.number="quantity"
            type="number"
            hide-details="auto"
            dense
            style="background-color: white"
            :rules="[
            v => (v || 0) <= stock.stock || `El stock máximo es de ${stock.stock}`,
            v => !!v || 'Campo obligatorio'
            ]"
            class="rounded-lg"
            ></v-text-field>

          <v-btn
            class="ml-auto rounded-lg"
            color="primary"
            depressed
            @click="moveDepotItem"
            >
            Asignar
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { MoveDepotItem } from '@/graphql/mutations/items'
import { Projects } from '@/graphql/queries/projects'
import { Rollouts } from '@/graphql/queries/rollouts'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    projectId: null,
    rolloutId: null,
    rollout: null,
    quantity: null,
    projects: [],
    rollouts: []
  }),

  props: {
    stock: {
      type: Object,
      required: true
    }
  },

  watch: {
    'projectId' (val) {
      if (val) {
        this.fetchRollouts()
      } else {
        this.rollouts = []
      }
    },

    rollout (rollout) {
      this.rolloutId = rollout.id
    }
  },

  created () {
    this.fetchProjects ()
  },                                                             

  methods: {                                                     
    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: Rollouts,
        variables: {
          projectId: this.projectId,
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rollouts
      })
    },

    moveDepotItem () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: MoveDepotItem,
          variables: {
            input: {
              id: this.stock.item.id,
              stockId: this.stock.id,
              rolloutId: this.rolloutId,
              quantity: this.quantity
            }
          }
        }).then (res => {
          this.rollout = null
          this.projectId = null
          this.rolloutId = null
          this.quantity = null

          this.dialog = false
          this.$emit('reload')
        })
      }
    }
  }
}
</script>
