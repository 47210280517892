import gql from 'graphql-tag'
import { Dispatch } from '@/graphql/fragments'

export const LatestMovements = gql`
  query latestMovements {
    latestMovements {
      envioTerreno {
        ...${ Dispatch }
      }
      devolucion {
        ...${ Dispatch }
      }
      interno {
        ...${ Dispatch }
      }
      nuevaTarea {
        ...${ Dispatch }
      }
      enajenacion {
        ...${ Dispatch }
      }
    }
  }
`
