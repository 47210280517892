<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    >
    <v-card-title
      style="gap: 8px"
      class="body-2 grey lighten-4"
      >
      <div
        style="position: relative"
        >
        <v-icon class="mt-n1" size="24" color="primary">mdi-package-down</v-icon> 

        <div
          style="width: 8px; height: 8px; position: absolute; top: 0px; right: 2px"
          class="red rounded-circle"
          ></div>
      </div>

      Últimos materiales ingresados
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      class="px-0"
      >
      <div
        v-if="stocks.length"
        >
        <template
          v-for="stock in stocks"
          >
          <v-card
            tile
            outlined
            style="margin-bottom: -1px; border-left: 0px; border-right: 0px"
            >
            <v-card-text
              class="d-flex flex-row text--primary justify-space-between align-center"
              style="gap: 12px"
              >
              <div>
                <div
                  >
                  <span class="font-weight-medium">Destino:</span> {{ stock.stockLocation.name }}
                </div>

                <div
                  >
                  <span class="font-weight-medium">Tarea:</span> {{ stock.rolloutId }}
                </div>
              </div>

              <div
                style="width: 120px"
                class="text-right"
                >
                <div>
                  <span class="font-weight-medium">Cod. prod.:</span> {{ stock.item.sku }}
                </div>

                <div>
                  <span class="font-weight-medium">N. serie:</span> {{ stock.serialNumber }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>

      <v-card
        v-else
        flat
        class="rounded-lg mx-4"
        outlined
        >
        <v-card-text
          class="text--primary"
          >
          Aún no hay movimientos aquí.
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { LatestStocks } from '@/graphql/queries/stocks'

export default {
  data: () => ({
    stocks: []
  }),

  created () {
    this.fetchStocks()
  },

  methods: {
    fetchStocks () {
      this.$apollo.query({
        query: LatestStocks,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.stocks = res.data.latestStocks
      })
    }
  }
}
</script>
