import gql from 'graphql-tag'

export const Stock = gql`
  query stock($itemId: ID!, $stockLocationId: ID!, $serialNumber: String) {
    stock(itemId: $itemId, stockLocationId: $stockLocationId, serialNumber: $serialNumber) {
      id
      stock
      serialNumber
      condition
      obs
      stockLocationId
    }
  }
`

export const Stocks = gql`
  query stocks($rolloutId: ID!, $stockLocationId: ID, $available: Boolean) {
    stocks(rolloutId: $rolloutId, stockLocationId: $stockLocationId, available: $available) {
      id
      stock
      serialNumber
      stockLocationId
      condition
      stockLocation {
        id
        name
      }
      item {
        id
        sku
        description
        condition
      }
    }
  }
`

export const SearchStocks = gql`
  query searchStocks($rolloutId: ID, $siteId: ID, $smr: String, $projectId: ID, $sku: String, $serialNumber: String, $product: String, $description: String) {
    searchStocks(rolloutId: $rolloutId, siteId: $siteId, smr: $smr, projectId: $projectId, sku: $sku, serialNumber: $serialNumber, product: $product, description: $description) {
      id
      stock
      serialNumber
      condition
      stockLocation {
        id
        name
      }
      rolloutId
      item {
        id
        sku
        description
        condition
      }
      lastMovement {
        id
        movementType
        approved
        createdAt
      }
    }
  }
`

export const LatestStocks = gql`
  query latestStocks {
    latestStocks {
      id
      stock
      serialNumber
      stockLocationId
      rolloutId
      condition
      item {
        id
        sku
        description
        condition
      }
      stockLocation {
        id
        name
      }
    }
  }
`
