<template>
  <div
    style="border-bottom: 1px solid #dedede; gap: 12px"
    class="px-3 py-2 d-flex flex-row justify-space-between"
    >
    <div
      >
      <div
        >
        <span class="font-weight-medium">Bodega:</span> {{ stock.stockLocation.name }}
      </div>

      <div
        >
        <span class="font-weight-medium">Tarea:</span> {{ stock.rolloutId }}
      </div>

      <div
        >
        <span class="font-weight-medium">Stock:</span> {{ stock.stock }}
      </div>
    </div>

    <div
      style="width: 120px"
      class="text-right grow"
      >
      <div>
        <span class="font-weight-medium">Cod. prod.:</span> {{ stock.item.sku }}
      </div>

      <div>
        <span class="font-weight-medium">N. serie:</span> {{ stock.serialNumber }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stock: {
      type: Object,
      required: true
    }
  }
}
</script>
