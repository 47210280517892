<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-hover
        v-slot="{ hover }"
        >
        <v-icon
          v-if="prefetched"
          v-bind="attrs"
          v-on="on"
          color="primary"
          >
          mdi-pencil
        </v-icon>

        <v-btn
          v-else
          color="primary"
          v-bind="attrs"
          v-on="on"
          depressed
          class="px-5 ml-auto rounded-xl"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px"
          >Añadir ítem</v-btn>
      </v-hover>
    </template>

    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          {{ prefetched ? 'Editar' : 'Añadir' }} ítem
        </v-card-title>

        <v-card-text
          class="d-flex flex-column"
          style="gap: 12px"
          >
          <div
            v-if="error"
            class="white--text text-center font-weight-medium px-4 py-2 error rounded-lg"
            >
            {{ error }}
          </div>

          <v-text-field
            id="sku"
            label="Código producto"
            :disabled="!!prefetched"
            v-model="attributes.sku"
            flat
            outlined
            dense
            hide-details
            class="rounded-lg"
            @blur="fetchItem"
            @keyup.enter="switchFocus('serialNumber', 'sku')"
            :rules="[
            v => !!v || 'Campo obligatorio.'
            ]"
            >
            <template
              v-slot:append
              >
              <v-tooltip 
                bottom
                color="primary"
                v-if="!!attributes.description"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    >
                    mdi-alert-box-outline
                  </v-icon>
                </template>
                <span>Material ya existente en el sitio.</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-text-field
            id="serialNumber"
            label="N° serie"
            v-model="stock.serialNumber"
            flat
            outlined
            dense
            hide-details
            class="rounded-lg"
            :rules="[
            v => !!v || 'Campo obligatorio.'
            ]"
            @blur="fetchStock"
            @keyup.enter="switchFocus('description')"
            :disabled="!fetchedItem"
            >
            <template
              v-slot:append
              >
              <v-tooltip 
                bottom
                color="primary"
                v-if="!stock.serialNumber"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="autoGenerate"
                    color="primary"
                    v-on="on"
                    v-bind="attrs"
                    >
                    mdi-autorenew
                  </v-icon>
                </template>
                <span>Generar número de serie automáticamente</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-card
            tile
            flat
            class="d-flex flex-column"
            style="gap: 12px"
            :disabled="!fetchedStock"
            >
            <v-text-field
              id="description"
              label="Descripción"
              v-model="attributes.description"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="switchFocus('volume')"
              :rules="[
              v => !!v || 'Campo obligatorio.'
              ]"
              ></v-text-field>

            <v-text-field
              id="volume"
              label="Volumen"
              v-model="attributes.volume"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="switchFocus('weight')"
              ></v-text-field>

            <v-text-field
              id="weight"
              label="Peso"
              v-model="attributes.weight"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="switchFocus('condition')"
              ></v-text-field>

            <v-select
              id="condition"
              label="Condición"
              v-model="stock.condition"
              :items="['Nuevo', 'Usado']"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="switchFocus('obs')"
              ></v-select>

            <v-textarea
              id="obs"
              label="Observaciones"
              v-model="stock.obs"
              flat
              outlined
              dense
              hide-details
              class="rounded-lg"
              ></v-textarea>

            <v-btn
              depressed
              color="primary"
              class="rounded-lg ml-auto"
              @click="save"
              >
              Guardar
            </v-btn>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { Item } from '@/graphql/queries/items'
import { Stock } from '@/graphql/queries/stocks'
import { EditItem } from '@/graphql/mutations/items'

export default {
  data: () => ({
    dialog: false,
    error: null,
    fetchedItem: false,
    fetchedStock: false,
    item: {
      id: null
    },
    stock: {
      id: null,
      stockLocationId: null,
      serialNumber: null,
      condition: 'Nuevo',
      stock: 0,
      obs: null,
    },
    attributes: {
      sku: null,
      description: null,
      volume: null,
      weight: null,
      condition: 'Nuevo',
      stocksAttributes: []
    }
  }),

  props: {
    entry: {
      required: true,
      type: Object
    },

    list: {
      required: true,
      type: Array,
    },

    prefetched: {
      required: false,
      type: Object
    }
  },

  created () {
    this.stock.stockLocationId = this.entry.stockLocationId

    if (this.prefetched) {
      this.attributes.sku = this.prefetched.sku

      this.fetchItem()
    }
  },

  watch: {
    dialog () {
      if (this.prefetched) {
        this.attributes.sku = this.prefetched.sku

        this.fetchItem()
      }
    }
  },

  methods: {
    fetchItem () {
      this.$apollo.query({
        query: Item,
        variables: {
          sku: this.attributes.sku
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.item = res.data.item

        Object.keys(this.attributes).forEach( k => {
          this.attributes[k] = res.data.item[k]
        })

        this.attributes.stocksAttributes = []

        this.fetchedItem = true

        this.$nextTick( () => {
          document.getElementById('serialNumber').focus()
        })

        if (this.prefetched) {
          this.stock.serialNumber = this.prefetched.serialNumber
          this.fetchStock()
        }
      })
    },

    fetchStock () {
      if (!this.prefetched && this.list.find( v => v.serialNumber == this.stock.serialNumber)) {
        this.error = `Ya cargó previamente un ítem con el número de serie ${this.stock.serialNumber}.`
      } else {
        this.$apollo.query({
          query: Stock,
          variables: {
            itemId: this.item.id,
            stockLocationId: this.entry.stockLocationId,
            serialNumber: this.stock.serialNumber
          },
          fetchPolicy: 'no-cache'
        }).then( res => {
          Object.keys(this.stock).forEach( k => {
            this.stock[k] = res.data.stock[k]
          })

          this.fetchedStock = true
          this.error = null
        }).catch ( error => {
          console.log(error)
          this.fetchedStock = false
          this.error = `Ya hay existencias en bodega con el número de serie ${this.stock.serialNumber}.`
        })
      }
    },

    save () {
      this.attributes.stocksAttributes.push(this.stock)
      delete this.attributes.id
      delete this.attributes.__typename

      this.$apollo.mutate({
        mutation: EditItem,
        variables: {
          input: {
            id: this.item.id,
            attributes: this.attributes
          }
        }
      }).then ( res => {
        this.$emit('reload', this.item.id, this.attributes, this.stock, !!this.prefetched)

        this.stock = {
          id: null,
          serialNumber: null,
          stock: 0,
          condition: 'Nuevo',
          obs: null
        }

        this.stock.stockLocationId = this.entry.stockLocationId

        this.attributes = {
          sku: null,
          description: null,
          volume: null,
          weight: null,
          stocksAttributes: []
        }

        this.fetchedItem = false
        this.fetchedStock = false

        this.dialog = false
      })
    },

    autoGenerate () {
      this.stock.serialNumber = this.item.nextStockNumber
    },

    switchFocus (element, toBlur = null) {
      if (toBlur)
        document.getElementById(toBlur).blur()

      document.getElementById(element).focus()
    }
  }
}
</script>
