import gql from 'graphql-tag'

export const DepotItems = gql`
  query depotItems {
    depotItems {
      id
      stock
      totalStock
      stockLocation {
        id
        name
      }
      item {
        id
        sku
        description
        volume
        weight
        condition
        totalStock
        freeStock
        obs
      }
    }
  }
`
