<template>
  <v-app>
    <appbar
      ></appbar>

    <v-main
      class="fill-height"
      >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { SignIn } from '@/graphql/mutations/users'
import { mapGetters } from 'vuex'

import Appbar from '@/components/bars/Appbar'

export default {
  name: 'App',

  data: () => ({
    //
  }),

  created () {
    if (!this.currentUser && this.isLoggedIn) {
      this.signIn()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn'])
  },

  methods: {
    signIn () {
      this.$apollo.mutate({
        mutation: SignIn,
        variables: {
          input: {
            token: localStorage.getItem('auth_token')
          }
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        let data = res.data.signIn

        if (data.token) {
          localStorage.setItem('auth_token', data.token)
          this.$store.commit("setCurrentUser", data.user)
        } else {
          localStorage.removeItem('auth_token')
        }
      }).catch(errors => {
        localStorage.removeItem('auth_token')
        this.$router.go()
      })
    },
  },

  components: {
    Appbar
  },
};
</script>
