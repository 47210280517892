import gql from 'graphql-tag'

export const LatestEntry = gql`
  query latestEntry($rolloutId: ID!) {
    latestEntry(rolloutId: $rolloutId) {
      id
      serialNumber
      item {
        id
        sku
        description
      }
    }
  }
`
