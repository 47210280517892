<template>
  <div
    class="d-flex fill-height flex-column"
    style="gap: 24px"
    >
    <entries-header
      @next="setAttributes"
      ></entries-header>

    <div
      class="d-flex flex-row"
      style="gap: 24px"
      :key="counter"
      >
      <list
        :attributes="attributes"
        style="min-height: 100%; max-height: calc(100vh - 380px); width: calc((100% / 3) * 2);"
        @reload="reload"
        ></list>

      <materials
        :attributes="attributes"
        style="min-height: 100%; max-height: calc(100vh - 380px); width: calc((100% / 3));"
        ref="materials"
        ></materials>
    </div>
  </div>
</template>

<script>
import EntriesHeader from '@/components/entries/Header'
import List from '@/components/entries/List'
import Materials from '@/components/entries/Materials'

export default {
  name: 'Home',

  data: () => ({
    attributes: null,
    counter: 0
  }),

  methods: {
    setAttributes (attributes) {
      this.attributes = attributes
      this.counter = this.counter + 1
    },
    
    reload () {
      this.$refs.materials.fetchLatestEntry()
    }
  },

  components: {
    EntriesHeader,
    List,
    Materials
  },
}
</script>
