<template>
  <div
    class="d-flex fill-height flex-row"
    style="gap: 24px"
    >
    <div
      style="width: calc(100% / 3); gap: 24px; position: relative"
      class="fill-height d-flex flex-column"
      >
      <div
        style="height: calc(100vh - 156px);"
        >
        <notifications></notifications>
      </div>

      <div
        style="max-height: calc(40% - 12px);"
        v-if="false"
        >
        <search></search>
      </div>
    </div>

    <div
      style="width: calc(100% / 3); gap: 24px"
      class="d-flex flex-column fill-height"
      >
      <movement
        style="max-height: calc(100vh - 156px)"
        ></movement>
    </div>

    <div
      style="width: calc(100% / 3)"
      class="d-flex flex-column fill-height"
      >
      <latest
        style="max-height: calc(100vh - 156px)"
        ></latest>
    </div>
  </div>
</template>

<script>
import Notifications from '@/components/home/Notifications'
import Search from '@/components/home/Search'
import Movement from '@/components/home/Movement'
import Latest from '@/components/home/Latest'

export default {
  name: 'Home',

  components: {
    Notifications,
    Search,
    Movement,
    Latest
  }
}
</script>
