<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    >
    <v-card-title
      style="gap: 8px"
      class="body-2 grey lighten-4"
      >
      <div
        style="position: relative"
        >
        <v-icon class="mt-n1" size="24" color="primary">mdi-bell</v-icon> 

        <div
          style="width: 8px; height: 8px; position: absolute; top: 0px; right: 2px"
          class="red rounded-circle"
          ></div>
      </div>

      <span class="font-weight-bold">{{ notifications.length }}</span> Notificaciones recibidas
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      class="px-0"
      >
      <template
        v-for="notification in notifications"
        v-if="notifications.length"
        >
        <notification
          :notification="notification"
          @reload="fetchNotifications"
          ></notification>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { Notifications } from '@/graphql/queries/notifications'

import Notification from '@/components/notifications/Notification'

export default {
  data: () => ({
    notifications: []
  }),

  created () {
    this.fetchNotifications ()
  },

  methods: {
    fetchNotifications () {
      this.$apollo.query({
        query: Notifications,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.notifications = res.data.notifications
      })
    }
  },

  components: {
    Notification
  }
}
</script>
