import gql from 'graphql-tag'

export const StockLocations = gql`
  query stockLocations {
    stockLocations {
      id
      name
    }
  }
`
