<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    :disabled="!dispatch"
    >
    <v-card-text
      class="d-flex flex-row"
      style="gap: 24px"
      >
      <v-card
        outlined
        class="rounded-lg"
        style="flex: 1"
        >
        <v-card-title
          style="gap: 8px"
          class="body-2"
          >
          <v-icon size="24" color="primary">mdi-package-up</v-icon> 

          Origen
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text
          class="px-0 pb-0"
          >
          <v-simple-table
            dense
            >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Código Producto
                  </th>
                  <th class="text-left">
                    N. Serie
                  </th>
                  <th class="text-left">
                    Descripción
                  </th>
                  <th class="text-left">
                    Cond.
                  </th>
                  <th class="text-left">
                    Cant.
                  </th>
                  <th class="text-left">
                    Bodega
                  </th>
                  <th class="text-left">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(stock, i) in stocks"
                  :key="stock.serialNumber"
                  >
                  <td>{{ stock.item.sku }}</td>
                  <td>{{ stock.serialNumber }}</td>
                  <td>{{ stock.item.description }}</td>
                  <td>{{ stock.condition }}</td>
                  <td>{{ stock.stock }}</td>
                  <td>{{ stock.stockLocation.name }}</td>
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="stock"
                      hide-details
                      class="mt-0 pt-0"
                      dense
                      ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <div
        class="d-flex align-center"
        >
        <v-hover
          v-slot="{ hover }"
          >
          <v-btn
            class="rounded-pill"
            color="primary"
            :style="(hover && selected && selected.length) ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
            style="height: 40px; width: 96px"
            @click="move"
            :disabled="!selected || !selected.length"
            >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-hover>
      </div>

      <v-card
        outlined
        class="rounded-lg"
        style="flex: 1"
        >
        <v-card-title
          style="gap: 8px"
          class="body-2"
          >
          <v-icon size="24" color="primary">mdi-package-down</v-icon> 

          Destino
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text
          class="px-0 pb-0"
          >
          <v-simple-table
            dense
            >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Código Producto
                  </th>
                  <th class="text-left">
                    N. Serie
                  </th>
                  <th class="text-left">
                    Descripción
                  </th>
                  <th class="text-left">
                    Cond.
                  </th>
                  <th class="text-left">
                    Cant.
                  </th>
                  <th class="text-left">
                    Bodega
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="stock in toDispatch"
                  :key="stock.serialNumber"
                  >
                  <td>{{ stock.item.sku }}</td>
                  <td>{{ stock.serialNumber }}</td>
                  <td>{{ stock.item.description }}</td>
                  <td>{{ stock.stockLocation.name }}</td>
                  <td>{{ stock.stock }}</td>
                  <td>{{ stock.condition }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-actions
      class="justify-end px-5"
      >
      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          class="rounded-pill"
          color="primary"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px; width: 96px"
          >
          Nuevo
        </v-btn>
      </v-hover>

      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          class="rounded-pill"
          color="primary"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px; width: 96px"
          @click="perform"
          :disabled="!toDispatch.length"
          >
          Ok
        </v-btn>
      </v-hover>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Stocks } from '@/graphql/queries/stocks'
import { CreateDispatch } from '@/graphql/mutations/dispatches'

export default {
  data: () => ({
    toDispatch: [],
    selected: [],
    stocks: []
  }),

  watch: {
    dispatch (val) {
      if (val) {
        this.fetchStocks()
      }
    }
  },

  created () {
    if (this.dispatch) {
      this.fetchStocks()
    }
  },

  props: {
    dispatch: {
      type: Object,
      required: false
    }
  },

  methods: {
    fetchStocks () {
      this.$apollo.query({
        query: Stocks,
        variables: {
          rolloutId: this.dispatch.originId,
          stockLocationId: this.dispatch.sourceId,
          available: true
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.stocks = res.data.stocks
      })
    },

    move () {
      this.selected.forEach ( item => {
        this.toDispatch.push(item)

        var index = this.stocks.indexOf(item)
        this.stocks.splice(index, 1)

      })

      this.selected = []
    },

    perform () {
      let movements = []

      this.toDispatch.forEach ( stock => {
        movements.push({
          movementType: this.dispatch.movementType,
          itemId: stock.item.id,
          stockId: stock.id,
          originId: this.dispatch.sourceId || stock.stockLocationId,
          destinationId: this.dispatch.stockLocationId,
          approved: this.dispatch.movementType == 'Mov. Nueva Tarea',
        })
      })

      let attributes = {
        ...this.dispatch,
        movementsAttributes: movements
      }

      this.$apollo.mutate({
        mutation: CreateDispatch,
        variables: {
          input: {
            attributes: attributes
          }
        }
      }).then( res => {
        this.$emit('next')
      })
    }
  }
}
</script>
