<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        icon
        small
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        Eliminar ítem {{ stock.item.sku }}
      </v-card-title>

      <v-card-text
        class="d-flex flex-column"
        style="gap: 12px"
        >
        <div
          class="text--primary body-1"
          >
          ¿Estás seguro de que deseas eliminar el ítem {{ stock.item.description }}? Esta acción no se puede deshacer.
        </div>

        <v-btn
          class="ml-auto rounded-lg"
          color="error"
          depressed
          @click="removeItem"
          >
          Eliminar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { RemoveDepotItem } from '@/graphql/mutations/items'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    stock: {
      type: Object,
      required: true
    }
  },

  methods: {                                                     
    removeItem () {
      this.$apollo.mutate({
        mutation: RemoveDepotItem,
        variables: {
          input: {
            id: this.stock.item.id,
            stockId: this.stock.id,
          }
        }
      }).then (res => {
        this.dialog = false
        this.$emit('reload')
      })
    }
  }
}
</script>
