<template>
  <v-card
    :disabled="!attributes"
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    >
    <v-card-title
      style="gap: 8px"
      class="body-2"
      >
      <v-icon size="24" color="primary">mdi-package</v-icon> 

      Materiales en la tarea
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      >
      <v-row
        class="rounded-lg grey lighten-4 caption"
        >
        <v-col
          cols="6"
          >
          Código de producto
        </v-col>

        <v-col
          cols="6"
          >
          Stock
        </v-col>
      </v-row>

      <div
        v-if="items.length"
        class="mt-3"
        >
        <v-row
          v-for="item in filteredItems"
          style="border-bottom: 1px solid #dedede"
          >
          <v-col
            cols="6"
            >
            {{ item.sku }}
          </v-col>

          <v-col
            cols="6"
            >
            {{ item.stock }}
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { LatestEntry } from '@/graphql/queries/entries'

export default {
  data: () => ({
    items: []
  }),

  props: {
    attributes: {
      required: false,
      type: Object
    }
  },

  watch: {
    attributes (val) {
      if (val) {
        this.fetchLatestEntry ()
      }
    }
  },

  created () {
    if (this.attributes) {
      this.fetchLatestEntry ()
    }
  },

  computed: {
    filteredItems () {
      let hash = {}

      this.items.forEach ( v => hash[v.item.sku] = (hash[v.item.sku] || 0) + 1 )

      let entries = Object.entries(hash)

      let result = []

      entries.forEach ( k => {
        result.push({
          sku: k[0],
          stock: k[1]
        })
      })

      return result
    }
  },

  methods: {
    fetchLatestEntry () {
      this.$apollo.query({
        query: LatestEntry,
        variables: {
          rolloutId: this.attributes.rolloutId
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.items = res.data.latestEntry
      })
    }
  }
}
</script>
