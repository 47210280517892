<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="rounded-lg"
    >
    <v-expansion-panels
      multiple
      flat
      tile
      >
      <v-expansion-panel
        v-for="stat in Object.entries(movements)"
        class="mt-0"
        >
        <v-expansion-panel-header
          class="body-2 grey lighten-4"
          >
          <div
            class="d-flex"
            style="gap: 4px"
            >
            <div
              style="position: relative"
              >
              <v-icon class="mt-n1" size="24" color="primary">mdi-package</v-icon> 

              <div
                style="width: 8px; height: 8px; position: absolute; top: 0px; right: 0px"
                class="red rounded-circle"
                ></div>
            </div>

            Últimos <span class="font-weight-bold">5</span> {{ translate[stat[0]] }}
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          class="py-4"
          >
          <div
            v-if="stat[1].length"
            >
            <v-expansion-panels
              accordion
              flat
              tile
              >
              <v-expansion-panel
                v-for="(dispatch, i) in stat[1]"
                style="border-top: 1px solid #dedede !important; border-bottom: 1px solid #dedede !important; margin-bottom: -1px"
                :key="i"
                >
                <v-expansion-panel-header>
                  <div>
                    <div
                      class="d-flex flex-row align-center"
                      style="gap: 8px"
                      >
                      <div
                        class="font-weight-medium body-1"
                        >
                        Egreso
                      </div>

                      <div>
                        -
                      </div>

                      <div
                        class="body-2"
                        >
                        {{ dispatch.movements.length }} item(s)
                      </div>
                    </div>
                    <div
                      class="caption"
                      >
                      Hace {{ transformTime(dispatch.elapsed) }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template
                    v-for="movement in dispatch.movements"
                    >
                    <v-card
                      tile
                      outlined
                      style="margin-bottom: -1px;"
                      >
                      <v-card-text
                        class="d-flex flex-row text--primary justify-space-between align-center"
                        style="gap: 12px"
                        >
                        <div>
                          <div>
                            <span class="font-weight-medium">Origen:</span> {{ movement.origin.name }}
                          </div>

                          <div
                            v-if="movement.destination"
                            >
                            <span class="font-weight-medium">Destino:</span> {{ movement.destination.name }}
                          </div>

                          <div
                            v-if="movement.originRolloutId"
                            >
                            <span class="font-weight-medium">Tarea origen:</span> {{ movement.originRolloutId }}
                          </div>

                          <div
                            v-if="movement.originRolloutId && movement.rolloutId && movement.rolloutId != movement.originRolloutId"
                            >
                            <span class="font-weight-medium">Tarea destino:</span> {{ movement.rolloutId }}
                          </div>
                        </div>

                        <div
                          style="width: 120px"
                          class="text-right"
                          >
                          <div>
                            <span class="font-weight-medium">N. mov.:</span> {{ movement.id }}
                          </div>

                          <div>
                            <span class="font-weight-medium">Cod. prod.:</span> {{ movement.stock.item.sku }}
                          </div>

                          <div>
                            <span class="font-weight-medium">N. serie:</span> {{ movement.stock.serialNumber }}
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <v-card
            v-else
            flat
            class="rounded-lg mx-4"
            outlined
            >
            <v-card-text
              class="text--primary"
              >
              Aún no hay movimientos aquí.
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { LatestMovements } from '@/graphql/queries/movements'

export default {
  data: () => ({
    movements: {},
    translate: {
      'envioTerreno': 'Envío terreno',
      'interno': 'Movimiento interno',
      'devolucion': 'Devolución',
      'nuevaTarea': 'Mov. nueva tarea',
      'enajenacion': 'Enajenación'
    }
  }),

  created () {
    this.fetchMovements ()
  },

  methods: {
    fetchMovements () {
      this.$apollo.query({
        query: LatestMovements,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.movements = res.data.latestMovements
        delete this.movements.__typename
      })
    },

    transformTime (seconds) {
      if (seconds < 60) {
        if (seconds == 1) return seconds + ' segundo'
        return seconds + ' segundos'
      } else if (seconds >= 60) {
        var min = Math.floor(seconds / 60)
        if (min == 1) return min + ' minuto'
        if (min < 60) return min + ' minutos'

        var hour = Math.floor(min / 60)
        if (hour == 1) return hour + ' hora'
        if (hour < 24) return hour + ' horas'

        var days = Math.floor(hour / 24)
        if (days == 1) return days + ' día'
        return days + ' días'
      }
    },
  }
}
</script>

<style scoped>
>>> .v-expansion-panel-content__wrap {
  padding: 0px 0px
}
</style>
