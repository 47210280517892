<template>
  <v-app-bar
    app
    color="primary"
    dark
    dense
    class="rounded-b-lg"
    >
    <div class="d-flex align-center font-weight-bold body-1">
      Tiantar - Basit
    </div>

    <v-spacer></v-spacer>

    <div
      v-if="currentUser"
      class="caption text-right"
      >
      <div>
        {{ currentUser.email }}
      </div>

      <div>
        {{ (new Date).toLocaleDateString() }}
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Appbar',

  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
