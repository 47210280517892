import gql from 'graphql-tag'

export const Item = gql`
  query item($sku: String!, $itemType: String) {
    item(sku: $sku, itemType: $itemType) {
      id
      sku
      description
      volume
      weight
      condition
      nextStockNumber
      obs
    }
  }
`

export const DetailedItem = gql`
  query detailedItem($id: ID!) {
    detailedItem(id: $id) {
      id
      sku
      description
      totalStock
      freeStock

      stocks {
        id
        stock
        rolloutId
        serialNumber
        stockLocation {
          id
          name
        }
      }
    }
  }
`
