<template>
  <v-card 
    outlined
    tile
    >
    <v-card-title
      class="align-start"
      style="border-bottom: 1px solid #dedede"
      >
      <div
        class="d-flex flex-column"
        style="gap: 4px"
        >
        <div
          class="d-flex flex-row align-center fill-width"
          style="gap: 8px"
          >
          <div
            class="font-weight-medium body-1"
            >
            Egreso
          </div>

          <div>
            -
          </div>

          <div
            class="body-2"
            >
            {{ dispatch.movements.length }} item(s)
          </div>
        </div>

        <div
          class="d-flex flex-row align-center fill-width"
          style="gap: 8px"
          >
          <div
            class="font-weight-medium body-1"
            >
            {{ dispatch.movementType }}
          </div>
        </div>

        <div
          class="body-2"
          >
          Tarea origen: {{ dispatch.originId }} {{ dispatch.rolloutId ? `- Tarea destino: ${ dispatch.rolloutId }` : '' }}
        </div>

        <div
          class="body-2"
          v-if="dispatch.source"
          >
          Bodega origen: {{ dispatch.source.name }} {{ dispatch.stockLocationId ? `- Bodega destino: ${ dispatch.stockLocation.name }` : '' }}
        </div>
      </div>

      <div
        class="caption ml-auto"
        >
        Hace {{ transformTime(dispatch.elapsed) }}
      </div>
    </v-card-title>

    <v-card-text
      class="px-0"
      >
      <v-simple-table
        dense
        >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left caption">
                Cód. Producto
              </th>
              <th class="text-left caption">
                N. Serie
              </th>
              <th class="text-left caption">
                Descripción
              </th>
              <th class="text-left caption">
                Bodega
              </th>
              <th class="text-left caption">
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(movement, i) in dispatch.movements"
              :key="movement.id"
              >
              <td>{{ movement.stock.item.sku }}</td>
              <td>{{ movement.stock.serialNumber }}</td>
              <td>{{ movement.stock.item.description }}</td>
              <td>{{ movement.stock.stockLocation.name }}</td>
              <td>
                <v-checkbox
                  v-model="selected"
                  :value="movement.id"
                  hide-details
                  class="mt-0 pt-0"
                  dense
                  v-if="!movement.approved"
                  ></v-checkbox>

                <v-icon
                  v-else
                  size="20"
                  color="primary"
                  >
                  mdi-check-bold
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div
        class="px-4 text-right mt-3"
        >
        <v-btn
          class="rounded-lg mr-2"
          depressed
          color="primary"
          small
          @click="download"
          >
          Descargar planilla
        </v-btn>

        <v-btn
          class="rounded-lg"
          depressed
          color="primary"
          small
          :disabled="!selected.length"
          @click="approve"
          >
          Confirmar despacho
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ApproveMovements } from '@/graphql/mutations/movements'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    selected: []
  }),

  props: {
    dispatch: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url', 'currentUser'])
  },

  methods: {
    transformTime (seconds) {
      if (seconds < 60) {
        if (seconds == 1) return seconds + ' segundo'
        return seconds + ' segundos'
      } else if (seconds >= 60) {
        var min = Math.floor(seconds / 60)
        if (min == 1) return min + ' minuto'
        if (min < 60) return min + ' minutos'

        var hour = Math.floor(min / 60)
        if (hour == 1) return hour + ' hora'
        if (hour < 24) return hour + ' horas'

        var days = Math.floor(hour / 24)
        if (days == 1) return days + ' día'
        return days + ' días'
      }
    },

    approve () {
      this.$apollo.mutate({
        mutation: ApproveMovements,
        variables: {
          input: {
            ids: this.selected
          }
        }
      }).then ( res => {
        this.selected = []
        this.$emit('reload')
      })
    },

    download () {
      window.open(`${this.url}/planilla_egreso/${this.dispatch.id}?uid=${this.currentUser.id}`, '_blank')
    }
  }
}
</script>
