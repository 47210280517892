<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="1200"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          outlined
          class="rounded-b-0 rounded-tl-lg rounded-tr-0"
          color="primary"
          style="margin-bottom: -1px; border-width: 0px 0px 2px 0px"
          :style="hover ? 'box-shadow: 0px 6px var(--v-primary-base); transform: translate(0px, -6px)' : 'box-shadow: 0 0'"
          v-on="on"
          v-bind="attrs"
          >
          <v-icon color="primary">mdi-magnify</v-icon> Buscador
        </v-btn>
      </v-hover>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-icon
          class="mr-2"
          color="primary"
          >mdi-magnify</v-icon> Buscador
      </v-card-title>

      <v-card-text
        class="text--primary"
        >
        <div
          v-if="searching"
          >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr
                  class="primary"
                  >
                  <th class="text-left white--text">
                    Bodega
                  </th>
                  <th class="text-left white--text">
                    ID Tarea
                  </th>
                  <th class="text-left white--text">
                    Stock
                  </th>
                  <th class="text-left white--text">
                    Cód. Producto
                  </th>
                  <th class="text-left white--text">
                    Número de Serie
                  </th>
                  <th class="text-left white--text">
                    Estado
                  </th>
                  <th class="text-right white--text">
                    Último Movimiento
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="result in results"
                  :key="result.id"
                  >
                  <td>{{ result.stockLocation.name }}</td>
                  <td>{{ result.rolloutId }}</td>
                  <td>{{ result.stock }}</td>
                  <td>{{ result.item.sku }}</td>
                  <td>{{ result.serialNumber }}</td>
                  <td>{{ result.lastMovement.movementType }}</td>
                  <td
                    style="width: 130px"
                    class="text-right"
                    >{{ (new Date(result.lastMovement.createdAt)).toLocaleDateString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <v-row
          v-else
          >
          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Guía / SMR
              </v-card-text>
            </v-card>

            <v-text-field
              flat
              outlined
              dense
              hide-details
              v-model="filters.smr"
              class="rounded-lg rounded-tl-0"
              ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Proyecto
              </v-card-text>
            </v-card>

            <v-select
              flat
              outlined
              dense
              hide-details
              :items="projects"
              item-text="name"
              item-value="id"
              v-model="filters.projectId"
              class="rounded-lg rounded-tl-0"
              clearable
              ></v-select>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Sitio
              </v-card-text>
            </v-card>

            <v-select
              flat
              outlined
              dense
              hide-details
              :items="sites"
              item-text="siteId"
              item-value="id"
              v-model="filters.siteId"
              class="rounded-lg rounded-tl-0"
              clearable
              ></v-select>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Tarea
              </v-card-text>
            </v-card>

            <v-select
              flat
              outlined
              dense
              hide-details
              :items="rollouts"
              item-text="id"
              item-value="id"
              v-model="filters.rolloutId"
              class="rounded-lg rounded-tl-0"
              clearable
              ></v-select>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                SKU
              </v-card-text>
            </v-card>

            <v-text-field
              flat
              outlined
              dense
              hide-details
              v-model="filters.serialNumber"
              class="rounded-lg rounded-tl-0"
              ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Código Producto
              </v-card-text>
            </v-card>

            <v-text-field
              flat
              outlined
              dense
              hide-details
              v-model="filters.product"
              class="rounded-lg rounded-tl-0"
              ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            >
            <v-card
              style="width: fit-content; margin-bottom: -1px"
              outlined
              class="rounded-b-0 rounded-t-lg"
              >
              <v-card-text
                class="py-1"
                >
                Description
              </v-card-text>
            </v-card>

            <v-text-field
              flat
              outlined
              dense
              hide-details
              v-model="filters.description"
              class="rounded-lg rounded-tl-0"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="searching"
          color="primary"
          class="rounded-lg"
          depressed
          @click="searching = false"
          >
          Volver
        </v-btn>

        <v-btn
          v-else
          color="primary"
          depressed
          class="rounded-lg"
          @click="fetchStocks"
          >
          Buscar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SearchStocks } from '@/graphql/queries/stocks'
import { Projects } from '@/graphql/queries/projects'
import { Rollouts } from '@/graphql/queries/rollouts'
import { Sites } from '@/graphql/queries/sites'

import Item from './Item'

export default {
  data: () => ({
    dialog: false,
    projects: [],
    rollouts: [],
    sites: [],
    results: [],
    searching: false,
    state: ['assigned', 'installing', 'installed', 'pre_approved', 'approved', 'partial', 'partial_billed', 'total'],
    filters: {
      sku: null,
      smr: null,
      projectId: null,
      serialNumber: null,
      product: null,
      description: null,
    }
  }),

  created () {
    this.fetchProjects ()
  },

  watch: {
    'filters.projectId' () {
      this.fetchRollouts ()
      this.fetchSites ()
    }
  },

  methods: {
    fetchStocks () {
      this.results = []

      this.$apollo.query({
        query: SearchStocks,
        variables: {
          ...this.filters
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.results = res.data.searchStocks

        this.searching = true
      })
    },

    fetchProjects () {
      this.$apollo.query({
        query: Projects,
      }).then ( res => {
        var last_project = ''

        res.data.projects.forEach ( project => {
          if (last_project != project.operator.name) {
            this.projects.push({ header: project.operator.name })
            last_project = project.operator.name
          }

          this.projects.push(project)
        })
      })
    },

    fetchRollouts () {
      this.$apollo.query({
        query: Rollouts,
        variables: {
          projectId: this.filters.projectId,
          state: this.state
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.rollouts = res.data.rollouts
      })
    },

    fetchSites () {
      this.$apollo.query({
        query: Sites,
        variables: {
          projectId: this.filters.projectId
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.sites = res.data.sites
      })
    },
  },

  components: {
    Item
  }
}
</script>
