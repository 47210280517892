<template>
  <v-card
    style="box-shadow: 6px 6px var(--v-primary-lighten2); overflow-y: auto"
    class="fill-height rounded-lg"
    :disabled="!attributes"
    >
    <v-card-title
      style="gap: 8px"
      class="body-2"
      >
      <v-icon size="24" color="primary">mdi-package-down</v-icon> 

      Ingreso
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      style="overflow: hidden"
      >
      <list-header></list-header>

      <template
        v-for="(item, i) in list"
        >
        <v-hover
          v-slot="{ hover }"
          >
          <v-row
            class="caption"
            style="border-bottom: 1px solid #dedede; position: relative"
            >
            <v-col
              cols="2"
              >
              {{ item.sku }}
            </v-col>

            <v-col
              cols="2"
              >
              <div
                class="description-box"
                >
                {{ item.description }}
              </div>
            </v-col>

            <v-col
              cols="2"
              >
              <div
                class="description-box"
                >
                {{ item.serialNumber }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ item.volume }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ item.weight }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ item.stock }}
              </div>
            </v-col>

            <v-col
              cols="1"
              >
              <div
                class="description-box"
                >
                {{ item.condition }}
              </div>
            </v-col>

            <v-col
              cols="2"
              >
              <div
                class="description-box"
                >
                {{ item.obs }}
              </div>
            </v-col>

            <div
              class="text-right"
              style="position: absolute; top: calc(50% - 12px); transform: translate(60px); transition: all ease .2s; width: 100%"
              :style="hover ? 'transform: translate(-12px); background-color: rgba(255, 255, 255, .5)' : ''"
              >
              <new-item
                :entry="attributes"
                :list="list"
                :prefetched="item"
                @reload="reload"
                ></new-item> 

              <v-dialog
                width="500"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="error"
                    class="ml-2"
                    v-on="on"
                    v-bind="attrs"
                    >
                    mdi-delete
                  </v-icon>
                </template>

                <v-card>
                  <v-card-title>
                    Eliminar {{ item.serialNumber }}
                  </v-card-title>

                  <v-card-text
                    class="body-1 text--primary"
                    >
                    ¿Está seguro de que desea eliminar este ítem?
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      outlined
                      color="error"
                      @click="remove(item, i)"
                      >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-row>
        </v-hover>
      </template>
    </v-card-text>

    <v-card-actions
      style="gap: 12px"
      class="justify-end"
      >
      <new-item
        v-if="!!attributes"
        :entry="attributes"
        :list="list"
        @reload="reload"
        ></new-item> 

      <v-hover
        v-slot="{ hover }"
        >
        <v-btn
          class="rounded-pill"
          color="primary"
          :style="hover ? 'box-shadow: 4px 4px var(--v-primary-lighten2); transform: translate(-4px, -4px)' : 'box-shadow: 0 0'"
          style="height: 40px"
          @click="save"
          >
          Ok
        </v-btn>
      </v-hover>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CreateEntry } from '@/graphql/mutations/entries'

import ListHeader from './ListHeader'
import NewItem from './NewItem'

export default {
  data: () => ({
    movements: [],
    list: []
  }),

  props: {
    attributes: {
      required: false,
      type: Object
    }
  },

  components: {
    ListHeader,
    NewItem
  },

  methods: {
    reload (id, item, stock, edit) {
      let newItem = {
        itemId: id,
        originId: null,
        destinationId: stock.stockLocationId,
        stockId: stock.id,
        amount: 1,
        movementType: 'Ingreso'
      }

      if (edit) {
        let aux = this.list.find( v => v.id == id )
        let idx = this.list.indexOf(aux)

        this.remove(newItem, idx)
      }

      this.movements.push(newItem)

      this.list.push({
        id: id,
        sku: item.sku,
        description: item.description,
        serialNumber: stock.serialNumber,
        volume: item.volume,
        weight: item.weight,
        stock: 1,
        condition: stock.condition,
        obs: stock.obs
      })
    },

    save () {
      var attributes = this.attributes

      attributes.movementsAttributes = this.movements

      this.$apollo.mutate({
        mutation: CreateEntry,
        variables: {
          input: {
            attributes: attributes
          }
        }
      }).then ( res => {
        this.list = []
        this.movements = []

        this.$emit('reload')
      })
    },

    remove (item, i) {
      let movement = this.movements.find( v => v.itemId == item.id)
      let idx = this.movements.indexOf(movement)

      this.movements.splice(idx, 1)
      this.list.splice(i, 1)
    }
  }
}
</script>
